<template>
  <div id="prices">
    <BackBtn />
    <h1>PRICES</h1>
    <p>
      you wanna join the knallkult?<br />we got you covered!<br /><br />

      wether it's an app, a website, a logo, graphic, 2d or 3d animations,
      animated visuals, a full fledged music video, or some banging beats for
      your project we got the sauce!<br /><br />

      prices for simple visuals and graphics start as low as 50€, while non
      repetative music videos and animations cost from 500€ upwards. but no
      matter what your budget is, we will find a way to get you something thats
      worth the money!<br /><br />
      to get an offer regarding your specific request, just write an email to
      <a href="mailto:info@knallkultur.de">info@knallkultur.de</a>

      <br /><br />
      let's create something amazing!
      <br />
      PENG PENG!
    </p>
  </div>
</template>

<script>
import { isFirstPage, router } from "@/main";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  setup() {
    const goBack = () => {
      if (!isFirstPage) router.go(-1);
      else
        router.push({
          name: "feed",
          params: { IDpost: "all", filter: "unfiltered" },
        });
    };
    return { goBack };
  },
});
</script>
