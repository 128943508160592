import { createRouter, createWebHistory } from "vue-router";

import Center_Feed from "./components/Center_Feed.vue";
import Center_Imprint from "./components/Center_Imprint.vue";
import Center_Prices from "./components/Center_Prices.vue";

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: {
        name: "feed",
        params: { IDpost: "all", filter: "unfiltered" },
      },
    },
    { name: "feed", path: "/feed/:IDpost&:filter", component: Center_Feed },
    { name: "imprint", path: "/imprint", component: Center_Imprint },
    { name: "prices", path: "/prices", component: Center_Prices },
  ],
});
export let isFirstPage = undefined as undefined | boolean;
router.beforeEach((to, from, next) => {
  if (isFirstPage == null) isFirstPage = true;
  else isFirstPage = false;
  next();
});

import { createApp } from "vue";
import App from "./App.vue";

import Fadein from "./components/Fadein.vue";
import Bgr from "./components/Bgr.vue";
import Top from "./components/Top.vue";
import Footer from "./components/Footer.vue";
import Viewer from "./components/Viewer.vue";
import BackBtn from "./components/BackBtn.vue";

createApp(App)
  .component("Fadein", Fadein)
  .component("Bgr", Bgr)
  .component("Top", Top)
  .component("Footer", Footer)
  .component("Viewer", Viewer)
  .component("BackBtn", BackBtn)
  .use(router)
  .mount("#app");

import * as Hammer from "hammerjs";
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const stage = document.getElementById("app")!;
export const mc = new Hammer.Manager(stage);
const Swipe = new Hammer.Swipe({direction: Hammer.DIRECTION_HORIZONTAL});
mc.add(Swipe);


