<template>
  <div id="bgr">
    <video
      v-for="singleTheme in themes"
      :key="'bgr_' + singleTheme"
      :id="'bgr_' + singleTheme"
      :active="theme == singleTheme"
      autoplay
      muted
      loop
      playsinline
    >
      <source :src="'/themes/' + singleTheme + '.mp4'" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import { defineComponent, watch } from '@vue/runtime-core';

export default defineComponent({
  props: ['theme', 'themes'],
  setup(props) {
    watch(
      () => props.theme,
      (theme, prevTheme) => {
        document.querySelector('#bgr_' + prevTheme).pause();
        document.querySelector('#bgr_' + theme).play();
      }
    );

    return {};
  },
});
</script>
