<template>
  <div id="footer">
    <!-- <a class="textlink">Ⓒ KNALLKULTUR 2021</a> -->
    <a class="textlink" @click="loadPage('prices')">Prices</a>
    <a class="textlink" @click="loadPage('imprint')">Imprint</a>
  </div>
</template>

<script>
import { router } from "@/main";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  setup() {
    const loadPage = (name) => {
      
      router.push({ name });
      window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    };
    return { loadPage };
  },
});
</script>
