<template>
  <div
    id="viewer"
    v-if="viewerStatus != 'unused'"
    :style="'order: ' + currentViewerPosition"
  >
    <!-- title -->
    <!-- <h1>{{ currentPost.name }}</h1> -->

    <!-- watch link -->
    <a
      v-if="currentPost.link && currentPost.link != 'pending'"
      :href="currentPost.link"
      target="_blank"
      id="overlay"
    >
      {{ currentPost.linkLabel }}
    </a>

    <!-- fader -->
    <Fadein
      v-if="viewerStatus != 'loaded_video' && viewerStatus != 'loaded_image'"
      :speed="150"
    />

    <!-- image -->
    <img
      v-if="viewerStatus != 'loaded_video' && currentPost.type != 'mp4'"
      id="viewerImage"
      :src="'/posts/' + currentPost.IDpost + '/source.jpg'"
      @loadstart="$emit('loadstart')"
      @load="$emit('loadimage')"
      @click="$emit('next')"
    />

    <!-- video -->
    <template v-if="currentPost.type == 'mp4'">
      <video
        autoplay
        loop
        @loadstart="$emit('loadstart')"
        @canplay="$emit('loadvideo')"
        @timeupdate="updateProgress"
        @click="$emit('next')"
        :poster="'/posts/' + currentPost.IDpost + '/source.jpg'"
      >
        <source :src="'/posts/' + currentPost.IDpost + '/source.mp4'" />
      </video>
      <progress id="progress" max="100" :value="playerProgress"></progress>
    </template>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/runtime-core';
export default defineComponent({
  props: [
    'viewerStatus',
    'currentPost',
    'currentViewerPosition',
    'lastPost',
    'isplaying',
  ],
  setup() {
    const playerProgress = ref(0);
    const updateProgress = (e) => {
      playerProgress.value = (100 / e.target.duration) * e.target.currentTime;
    };
    return { playerProgress, updateProgress };
  },
});
</script>
