<template>
  <a id="back" @click="goBack()">
    <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 12.658 7.499 C 12.658 10.348 10.349 12.658 7.5 12.658 C 4.651 12.658 2.342 10.348 2.342 7.499 C 2.342 4.651 4.651 2.341 7.5 2.341 C 10.349 2.341 12.658 4.651 12.658 7.499 Z M 7.5 6.51 L 5.519 4.529 L 4.528 5.519 L 6.509 7.501 L 4.529 9.481 L 5.519 10.472 L 7.5 8.491 L 9.481 10.472 L 10.471 9.481 L 8.491 7.501 L 10.472 5.519 L 9.481 4.529 Z"
      ></path>
    </svg>
  </a>
</template>

<script>
import { isFirstPage, router } from "@/main";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  setup() {
    const goBack = () => {
      if (!isFirstPage) router.go(-1);
      else
        router.push({
          name: "feed",
          params: { IDpost: "all", filter: "unfiltered" },
        });
    };
    return { goBack };
  },
});
</script>


