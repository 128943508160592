<template>
  <Top :theme="theme" :themes="themes" @tagclick="tagClick($event)"/>

  <!-- posts and categories -->
  <a
    v-for="(post, index) in filteredPosts"
    :key="'post_' + index"
    class="thumb"
    :style="{ order: index }"
    :id="post.IDpost"
    :IDpost="post.IDpost"
    :active="currentPost && currentPost.IDpost == post.IDpost"
    :order="index"
    :type="post.type"
    @click="
      () => {
        loadPost({
          IDpost:
            currentPost && currentPost.IDpost == post.IDpost
              ? 'all'
              : post.IDpost,
          dir: null,
        });
      }
    "
  >
    <!-- <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 12.658 7.499 C 12.658 10.348 10.349 12.658 7.5 12.658 C 4.651 12.658 2.342 10.348 2.342 7.499 C 2.342 4.651 4.651 2.341 7.5 2.341 C 10.349 2.341 12.658 4.651 12.658 7.499 Z M 8.239 9.844 C 8.988 10.276 9.379 10.09 9.414 9.286 C 9.328 8.686 7.581 8.179 7.575 8.224 C 6.79 7.771 6.79 7.318 7.575 6.864 C 7.568 6.881 9.51 6.33 9.417 5.8 C 9.406 4.924 9.014 4.709 8.239 5.156 L 5.358 6.82 C 4.573 7.273 4.573 7.727 5.358 8.18 Z"
      ></path>
    </svg> -->
    <!-- <svg v-if="post.type == 'mp4'" viewBox="-10 -10 90 100">
        <path
          d="M 12.317 2.235 L 70.659 35.919 L 12.317 69.603 L 12.317 2.235 Z"
        />
      </svg> -->
    <img v-if="post.type" :src="'/posts/' + post.IDpost + '/thumb.jpg'" />
    <h1>{{post.name}}</h1>
  </a>

  <Viewer
    v-if="ready"
    :viewerStatus="viewerStatus"
    :currentPost="currentPost"
    :lastPost="filteredPosts.length - 1"
    :currentViewerPosition="currentViewerPosition"
    :isplaying="isplaying"
    @next="loadPost({ IDpost: currentPost.IDpost, dir: 1 })"
    @prev="loadPost({ IDpost: currentPost.IDpost, dir: -1 })"
    @loadstart="viewerStatus = 'loading'"
    @loadimage="viewerStatus = 'loaded_image'"
    @loadvideo="viewerStatus = 'loaded_video'"
    @videoclick="videostopstart()"
  />

  <Footer />
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
// import posts from "@/../public/posts.json";
import { mc, router } from "../main";

export default defineComponent({
  props: ["theme", "themes"],
  setup(props,{emit}) {
    const ready = ref(false);

    onMounted(() => {
      console.log("mount");

      setTimeout(() => {
        ready.value = true;
        if (router.currentRoute.value.params.IDpost != "all") {
          viewerStatus.value = "loading";
        }
      }, 200);
    });

    
    

    let posts = ref([])
    axios.get("posts.json", {
      baseURL: window.location.origin,
      headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}
    }).then(ret=> {posts.value = ret.data});
    console.log(posts);

    const filteredPosts = computed(() => {
      const ret =
        router.currentRoute.value.params.filter == "unfiltered"
          ? posts.value.filter((item) => item.IDpost != "all" && item.hidden != true)
          : posts.value.filter(
              (item) =>
                item.IDpost != "all" &&
                item.hidden != true &&
                item.tags &&
                item.tags.indexOf(router.currentRoute.value.params.filter) != -1
            );
      return ret;
    });

    // const curPostFlicker = ref(false);
    const currentPost = computed(() => {
      if (!ready.value) return null;
      if (
        ["all", "imprint"].indexOf(router.currentRoute.value.params.IDpost) ==
        -1
      ) {
        let INDEXpost = -1;
        const newPost = filteredPosts._value.find((item, index) => {
          if (item.IDpost == router.currentRoute.value.params.IDpost) {
            INDEXpost = index;
            return item;
          }
        });

        // setTimeout(() => {
        const el = document.querySelector(
          "#" + router.currentRoute.value.params.IDpost
        );
        const pos = el ? el.offsetTop : null;
        window.scrollTo({
          top: pos,
          behavior: "smooth",
        });
        // curPostFlicker.value = true;
        // }, 100);

        let linkBase = null;
        if (newPost && newPost.link && newPost.link == "pending") {
          linkBase = "pending";
        } else if (newPost && newPost.link) {
          linkBase = newPost.link.split("www.")[1].split(".")[0];
        }

        return {
          ...newPost,
          index: INDEXpost,
          linkLabel: {
            youtube: "watch",
            vimeo: "watch",
            pending: "pending",
          }[linkBase],
        };
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return null;
      }
    });

    const loadPost = ({ IDpost, dir }) => {

      emit('templatechange')

      if (dir) {
        const index = !currentPost._value ? -1 : currentPost._value.index;
        let nextPost = filteredPosts._value[index + dir];
        if (nextPost) IDpost = nextPost.IDpost;
        else IDpost = "all";
      }
      // if (IDpost == "all")
      viewerStatus.value = "unused";
      if (IDpost != "all") {
        setTimeout(() => (viewerStatus.value = "loading"), 1);
      }

      isplaying.value = true;

      router.push({
        name: "feed",
        params: { IDpost, filter: router.currentRoute.value.params.filter },
      });
    };

    window.addEventListener("keyup", (e) => {
      if (e.key == "ArrowLeft" || e.key == "ArrowRight") {
        loadPost({
          IDpost: currentPost.IDpost,
          dir: e.key == "ArrowLeft" ? -1 : 1,
        });
      }
    });

    mc.on("swipe", (ev) => {
      if (ev.direction == 2) {
        console.log("next");
        loadPost({
          IDpost: currentPost.IDpost,
          dir: 1,
        });
      } else if (ev.direction == 4) {
        console.log("back");
        loadPost({
          IDpost: currentPost.IDpost,
          dir: -1,
        });
      }
    });

    const isplaying = ref(true);
    const videostopstart = () => {
      const vid = document.querySelector("#viewer video");
      if (isplaying.value == true) vid.pause();
      else vid.play();
      isplaying.value = !isplaying.value;
    };

    const currentViewerPosition = computed(() => {
      let isInLine = false;
      let lastY;
      let viewerIndex;
      filteredPosts._value.find((item, index) => {
        if (item.IDpost == router.currentRoute.value.params.IDpost) {
          isInLine = true;
        }
        if (isInLine) {
          const el = document.querySelector("#" + item.IDpost);
          const pos = el ? el.getBoundingClientRect().y : null;
          if (pos && lastY && Math.abs(pos - lastY) > 10) {
            viewerIndex = index - 1;
            return true;
          } else {
            lastY = pos;
            viewerIndex = index;
          }
        }
      });
      if (!viewerIndex) console.error("viewerIndex", viewerIndex);
      return viewerIndex;
    });

    const viewerStatus = ref("unused");

    const postTags = [
      // "latest",
      ...Object.keys(
        posts.value.reduce((accumulator, item) => {
          if (item.tags) {
            for (let i = 0; i < item.tags.length; i++)
              accumulator[item.tags[i]] = true;
          }
          return accumulator;
        }, {})
      ),
    ];

    const tagClick = (filter) => {
      emit('templatechange')
      console.log(filter);
      viewerStatus.value = "unused";
      router.push({
        name: "feed",
        params: {
          IDpost: "all",
          filter: isActiveTag(filter) ? "unfiltered" : filter,
        },
      });
    };

    const isActiveTag = (tag) => {
      return router.currentRoute.value.params.filter == tag;
    };

    return {
      ready,
      posts,
      currentPost,
      currentViewerPosition,
      viewerStatus,
      loadPost,
      postTags,
      filteredPosts,
      tagClick,
      isActiveTag,
      isplaying,
      videostopstart,
    };
  },
});
</script>
