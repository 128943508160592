<template>
  <Bgr :theme="theme" :themes="themes" />
  <router-view :theme="theme" :themes="themes" @templatechange="templateChange()"></router-view>
</template>

<script>
import { defineComponent, ref } from '@vue/runtime-core';

export default defineComponent({
  setup() {
    const themes = ['stars', 'dealer', 'chainburn'];
    const theme = ref(false);

    const templateChange = () => {
      const other_themes = themes.filter((item) => item != theme.value);
      theme.value =
        other_themes[Math.round(Math.random() * (other_themes.length - 1))];
      document.querySelector('body').setAttribute('theme', theme.value);
    };
    
    templateChange(); // call to get cur_theme

    return {
      templateChange,
      themes,
      theme,
    };
  },
});
</script>

<style lang="scss">
@import '@/assets/look.scss';
</style>
